import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EnvironmentConfig } from '../../models/fall-alert/fall-alert-interfaces';
import { BaseDataService } from "./data.base.service";


@Injectable()
export class ConfigurationDataService extends BaseDataService {
    public environmentConfig: EnvironmentConfig = null;

    constructor(private _http: HttpClient) {
        super();
    }

    public loadConfiguration() : Promise<boolean> {
        return this.getConfiguration()
            .toPromise()
            .then(config => {
                this.environmentConfig = config;
                return Promise.resolve(true);
            })
            .catch(err=>{
                return Promise.resolve(false);
            }); 
    }

    private getConfiguration(): Observable<EnvironmentConfig> {
        return this._http.post<EnvironmentConfig>(`${environment.authorizationServiceUrl}/getFirebaseConfig`, null);
    }
}